import React, { useState } from "react";
import mens from "../assets/men.webp";
import womens from "../assets/women.webp";
import ajmal from "../assets/ajmal.png";
import hermes from "../assets/hermes.png";
import azzaro from "../assets/azzaro.png";
import calvinKelin from "../assets/calvin-kelin.png";
import jaguar from "../assets/jaguar.png";
import dng from "../assets/dng.png";
import nautica from "../assets/nautica.png";
import rasasi from "../assets/rasasi.png";
import HamburgerMenu from "../components/HamburgerMenu";
import { FaLocationDot } from "react-icons/fa6";
import { Zoom, Fade } from "react-reveal";
import Footer from "../components/Footer";
import { Parallax, ParallaxBanner } from "react-scroll-parallax";
import wMap from "../assets/worldMap.png";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <>
      <div className="">
        <HamburgerMenu />
        <div className="App h-[100vh] flex overflow-x-hidden">
          {/* SOCIALS */}
          <div className="md:w-[90px] 2xl:w-[130px] hidden md:block">
            <div className=" rotate-[270deg] flex gap-10 justify-center items-center h-full text-[#EBF7FD] pt-5 ">
              <span>Facebook</span>
              <span>Twitter</span>
              <span>Instagram</span>
            </div>
          </div>
          {/* BORDER */}
          <div className="h-full md:flex justify-center items-center hidden">
            <div className="bg-[#3D475E] w-[1px] h-[80vh]"></div>
          </div>

          {/* MAIN */}
          <div className="h-full flex justify-center items-center">
            <div className="h-[80vh] flex flex-col">
              <div className="flex justify-center items-center">
                <div
                  className="text-[60px] xs:text-[64px] sm:text-[100px] lg:text-[120px] 2xl:text-[180px] yeseva-font text-white mx-10 
                -mt-10 lg:mt-0"
                >
                  {/* <p className=" ml-[120px]">World</p> */}
                  <Fade top>
                    <p className=" md:ml-0 my-48 tracking-wide flex">
                      Prystine{" "}
                    </p>
                  </Fade>
                  {/* <p className=" -mt-16 ml-[240px]">Fragrance</p> */}
                </div>
                <div className=" absolute right-32 hidden xl:block w-[400px] mt-10 2xl:ml-40 lg:ml-32 mulish-font font-extralight text-xl">
                  {/* <Fade top> */}
                  <div className="text-white">
                    Available premium-quality men’s and women’s perfumes,
                    trusted by well-known brands, with proven quality, refresh
                    your day
                  </div>
                  <Link to={"/about-us"}>
                    <button className=" bg-[#E0EBFF] rounded-full w-[160px] py-3 text-center mt-5 font-semibold">
                      About us
                    </button>
                  </Link>
                  {/* </Fade> */}
                </div>
              </div>

              <div className=" mx-10 w-[80%] bottom-56 absolute lg:hidden mt-10 2xl:ml-40 lg:ml-32 mulish-font font-extralight text-base">
                <div className="text-white">
                  Available premium-quality men’s and women’s perfumes, trusted
                  by well-known brands, with proven quality, refresh your day
                </div>
                <Link to={"/about-us"}>
                  <button className=" bg-[#E0EBFF] rounded-full w-[140px] py-2 text-center mt-4 font-semibold">
                    About us
                  </button>
                </Link>
              </div>

              {/* <Fade bottom> */}
              <div className="hidden absolute bottom-20 lg:flex justify-between md:w-[65%] lg:w-[60%] xl:w-[60%] h-[100px] mx-16 ">
                <div className="mulish-font font-extralight text-base lg:text-xl text-white w-[380px] md:mr-10 tracking-wide lg:tracking-widest">
                  Discover the perfect fragrance for you with our wide selection
                  of perfumes.
                </div>
                <div className="flex gap-x-12">
                  <div className="flex flex-col gap-y-3">
                    <p className=" text-2xl lg:text-4xl font-semibold text-[#E0EBFF]">
                      90+
                    </p>
                    <p className="mulish-font text-base lg:text-xl text-white">
                      Perfumes
                    </p>
                  </div>

                  <div className=" h-full w-[1px] bg-[#3D475E]"></div>

                  <div className="flex flex-col gap-y-3">
                    <p className=" text-2xl lg:text-4xl font-semibold text-[#E0EBFF]">
                      15M+
                    </p>
                    <p className="mulish-font text-base lg:text-xl text-white">
                      Customers
                    </p>
                  </div>
                </div>
              </div>
              {/* </Fade> */}

              {/* for mobiles */}

              <Fade bottom>
                <div className="lg:hidden absolute bottom-20 md:flex justify-between items-center md:w-[65%] h-[100px] mx-10 ">
                  <div className="mulish-font font-extralight text-base lg:text-xl mr-6 text-white tracking-wider">
                    Discover the perfect fragrance for you with our wide
                    selection of perfumes.
                  </div>
                  <div className="flex gap-x-12 mt-5">
                    <div className="flex flex-col gap-y-3">
                      <p className=" text-2xl lg:text-4xl font-semibold text-[#E0EBFF]">
                        90+
                      </p>
                      <p className="mulish-font text-base lg:text-xl text-white">
                        Perfumes
                      </p>
                    </div>

                    <div className=" h-full w-[1px] bg-[#3D475E]"></div>

                    <div className="flex flex-col gap-y-3">
                      <p className=" text-2xl lg:text-4xl font-semibold text-[#E0EBFF]">
                        15M+
                      </p>
                      <p className="mulish-font text-base lg:text-xl text-white">
                        Customers
                      </p>
                    </div>
                  </div>
                </div>
              </Fade>
            </div>
            {/* <div 
            className="text-white absolute top-14 right-[120px] text-2xl cursor-pointer"
            onClick={()=>setShowMenu(true)}
          ><CiMenuBurger/></div> */}
          </div>
        </div>

        <div className="h-[210px] bg-[#F7F7F7] relative z-10">
          <div className="md:pt-20 pt-14 w-full xl:w-9/12 mx-auto flex gap-y-8 flex-col xl:flex-row xl:gap-x-16 2xl:gap-x-20 justify-center items-center h-full">
            <div className="flex gap-x-5 md:gap-x-20 xl:gap-x-16 2xl:gap-x-20 items-center">
              <Zoom>
                <div className=" w-[65px] md:w-[100px] aspect-square">
                  <img src={ajmal} alt="logo" className="xs:scale-75" />
                </div>
              </Zoom>
              <Zoom>
                <div className=" w-[65px] md:w-[100px] aspect-square">
                  <img src={hermes} alt="logo" />
                </div>
              </Zoom>
              <Zoom>
                <div className=" w-[65px] md:w-[100px] aspect-square">
                  <img src={azzaro} alt="logo" />
                </div>
              </Zoom>
              <Zoom>
                <div className=" w-[65px] md:w-[100px] aspect-square">
                  <img src={calvinKelin} alt="logo" />
                </div>
              </Zoom>
            </div>

            <div className="flex gap-x-5 md:gap-x-20 xl:gap-x-16 2xl:gap-x-20 items-center">
              <Zoom>
                <div className=" w-[65px] md:w-[100px] aspect-square">
                  <img src={jaguar} alt="logo" />
                </div>
              </Zoom>
              <Zoom>
                <div className=" w-[65px] md:w-[100px] aspect-square">
                  <img src={dng} alt="logo" />
                </div>
              </Zoom>
              <Zoom>
                <div className=" w-[65px] md:w-[100px] aspect-square">
                  <img src={nautica} alt="logo" />
                </div>
              </Zoom>
              <Zoom>
                <div className=" w-[65px] md:w-[100px] aspect-square">
                  <img src={rasasi} alt="logo" />
                </div>
              </Zoom>
            </div>
          </div>
        </div>

        <div className="brand-section relative z-10 py-20">
          <Fade clear>
            <div className=" w-11/12 rounded-2xl bg-[#E0EBFF] text-black mx-auto py-[20px] lg:py-16 text-center text-[16px] md:text-[30px] lg:text-[40px] px-[20px] lg:px-[240px] yeseva-font">
              It's an art. Craft. And science. At Ajmal perfumes, we're in the
              business of creating memories that are everlasting through our
              fragrances.
            </div>
          </Fade>
        </div>

        <div className="">
          <div className="App2 overflow-hidden w-[99vw]">
            <div className="flex flex-col md:flex-row w-full mx-auto">
              <div className=" cursor-pointer w-full lg:w-[50%] relative hover-underline-animation">
                <div className=" text-[20px] lg:text-[60px] absolute z-10 -translate-x-[50%] -translate-y-[50%] top-[50%] left-[50%] ml-5 mt-10 text-white font-semibold child px-3 xs:ml-2">
                  MEN's
                </div>
                <img
                  src={mens}
                  alt="mens perfume"
                  className="hover:scale-110 transition-all duration-1000 perfume"
                />
              </div>
              <div className=" cursor-pointer lg:w-[50%] relative hover-underline-animation">
                <div className="text-[20px] lg:text-[60px] absolute z-10 -translate-x-[50%] -translate-y-[50%] top-[50%] left-[50%] ml-5 mt-10 text-white font-semibold px-3 child xs:ml-2">
                  WOMENS's
                </div>
                <img
                  src={womens}
                  alt="womens perfume"
                  className=" hover:scale-110 transition-all duration-1000 perfume "
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="h-screen">
        <div className="back-gradient text-[#E0EBFF] -mt-2 z-10 absolute">
          <div className="flex lg:flex-row flex-col justify-center gap-y-20 lg:justify-between w-[88%] xl:w-9/12 mx-auto h-screen items-center">
            <div className="w-full lg:w-[40%]">
              <div className="yeseva-font flex flex-col xs:leading-tight">
                <Fade left>
                  <p className="text-[40px] lg:text-[50px] 2xl:text-[60px]">
                    Devoted to
                  </p>
                  <p className="text-[40px]  lg:text-[50px] 2xl:text-[60px] font-bold tracking-widest text-[#bad0fc]">
                    fragrances,
                  </p>
                  <p className="text-[40px]  lg:text-[50px] 2xl:text-[60px] tracking-wide">
                    committed to
                  </p>
                  <p className="text-[40px]  lg:text-[50px] 2xl:text-[60px] font-bold tracking-widest text-[#bad0fc]">
                    partners
                  </p>
                </Fade>
              </div>
            </div>

            <div className=" w-full lg:w-[50%] flex flex-col gap-y-16">
              <Fade right>
                <p className="text-[20px] lg:text-[25px] 2xl:text-[30px] leading-snug mulish-font">
                  Our purpose is to design and manufacture fragrances that
                  enhance our customers brands and build customer loyality
                </p>

                <p className="text-[20px] lg:text-[25px] 2xl:text-[30px] leading-snug mulish-font font-light">
                  We are a B2B company fully dedicated to our clients, with a
                  single-minded focus on producing high quality perfumes for
                  fine fragrances, home and personal care products.
                </p>
              </Fade>
            </div>
          </div>
        </div>
      </div>

      <div className=" h-screen w-full flex justify-between items-center text-black">
        <Parallax speed={-15}>
          <div className=" w-[85%] lg:w-[55%] mx-auto bg-[#E0EBFF] flex flex-col gap-y-10 rounded-lg text-center p-8 2xl:p-20">
            <Fade left>
              <div className="xl:mx-20">
                <p className="mulish-font text-[25px] lg:text-[60px]">
                  <span>We're Midsize</span>{" "}
                  <span className=" italic">, and Play</span>{" "}
                  <span className="font-bold">in The Big Leagues</span>
                </p>
              </div>
            </Fade>
            <Fade right>
              <div className="mulish-font text-lg font-medium tracking-wide lg:text-[20px] xl:mx-20">
                Being relevent in Business isn't related to size. We give you
                full access to our superior perfumes and services without
                cutting corners. Our sales and development teams will always
                offer you bespoke fragrance solutions for any category of
                products.
              </div>
            </Fade>
            <Fade left>
              <div>
                <Link to={"/about-us"}>
                  <button className=" bg-[white] text-black rounded-full w-[160px] py-3 text-center mt-5 font-semibold">
                    About us
                  </button>
                </Link>
              </div>
            </Fade>
          </div>
        </Parallax>
      </div>

      <div className="h-screen back-gradient">
        {/* <div className=" w-full h-full back-map"> */}
        <ParallaxBanner
          layers={[{ image: wMap, speed: -20 }]}
          className=" w-full h-full"
        >
          <div className="filter-drop w-full h-full">
            <div className="flex w-full h-full flex-col gap-y-10 justify-center items-center ">
              <Fade left>
                <p>
                  <FaLocationDot className=" text-white text-5xl" />
                </p>
                <div className="mulish-font text-[36px] lg:text-[60px] text-[white] text-center">
                  <span className=" italic">We're Closer </span>
                  <span className=" font-semibold"> than you </span>
                  <p className=" font-semibold">think</p>
                </div>
                <Link target="blank" to={"https://maps.app.goo.gl/xxk6zSkfb95NKfvE8?g_st=iw"}>
                  <button className=" bg-[#77a5ff] text-white rounded-full py-3 text-center mt-5 font-semibold px-7">
                    Get in Touch With us
                  </button>
                </Link>
              </Fade>
            </div>
          </div>
        </ParallaxBanner>
        {/* </div> */}
      </div>

      <Footer />
    </>
  );
};

export default Home;
