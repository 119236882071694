import React from "react";
import HamburgerMenu from "../components/HamburgerMenu";
import flower from "../assets/Aboutus-flower.jpeg";
import { ImQuotesLeft, ImQuotesRight } from "react-icons/im";
import executive from "../assets/executive.jpeg";
import Footer from "../components/Footer";
import Card from "../components/Card";
import cardImg1 from "../assets/cardImg1.jpg";
import cardImg2 from "../assets/cardImg2.jpg";
import { Zoom, Fade, Bounce } from "react-reveal";
import aboutusBgWrapper from "../assets/aboutus-bgwrapper.jpeg";
import { Parallax } from "react-scroll-parallax";

const AboutUs = () => {
  return (
    <div>
      <HamburgerMenu />
      <div className=" back-gradient h-[80vh] lg:h-[70vh]">
        <div className=" lg:w-9/12 h-full w-[90%] mx-auto flex flex-col sm:flex-row justify-between items-center">
          <Fade left>
            <div className=" sm:w-[55%] mt-40 sm:mt-0">
              <p className=" text-[25px] lg:text-[30px] mulish-font text-white my-10">
                A story of growth
              </p>
              <p className=" text-white text-[26px] md:text-[35px] 2xl:text-[50px]">
                <span className=" mulish-font italic font-extralight">
                  We're Growing Fast
                </span>
                <span className=" mulish-font ">
                  {" "}
                  While <br />
                  Offering The
                </span>
                <span className=" mulish-font font-bold">
                  {" "}
                  Best Bespoke <br /> Service
                </span>
                <span className=" mulish-font italic"> Possible</span>
              </p>
            </div>
          </Fade>
          <Fade right>
            <div className=" hidden sm:block w-[45%] mt-72">
              <Parallax speed={15}>
                <img src={flower} alt="Flower" className=" w-[100%]" />
              </Parallax>
            </div>

            <div className=" absolute sm:hidden bottom-24 w-[80%]">
              <Parallax speed={10}>
                <img src={flower} alt="Flower" className="" />
              </Parallax>
            </div>
          </Fade>
        </div>
      </div>

      <div className="h-[300px] bg-[#F7F7F7] w-full"></div>

      <div className="bg-[#F7F7F7]">
        {/* <div className=" w-[90%] md:w-9/12 mx-auto flex lg:flex-row flex-col-reverse gap-x-16 gap-y-10">
          <Fade left>
            <div className="lg:w-[40%] mt-10">
              <img
                src={executive}
                alt="executive"
                className=" object-contain"
              />
            </div>
          </Fade>
          <Fade right>
            <div className="bg-[#E0EBFF] lg:py-28 lg:px-16 px-5 py-16 lg:w-[70%]">
              <ImQuotesLeft className=" text-[50px] mb-5" />
              <div className=" mulish-font text-xl md:text-2xl px-3 md:px-8">
                Prystine International Trading LLC is a distributor of
                fragrances and skincare products. We offer a selection of
                popular products. We specialize in luxury brands of fragrances
                and skincare and makeup essentials and gift sets items at great
                prices to wholesalers and retailers. Our products are genuine
                and original, and we are trusted by wholesalers all over the
                world. 
                <br/><br/>
                With over 20 years of experience in distribution and
                supply chain management, we can ship anywhere in the world. We
                have branch offices in the United States and United Kingdom, and
                we carry an inventory of best-selling fragrances and skincare
                products.
              </div>
              <ImQuotesRight className=" float-right text-[50px] mt-5" />
              <div className=" mt-20 md:mt-10">
                <p className=" mulish-font text-lg font-semibold">
                  Laurent Mercier
                </p>
                <p className=" mulish-font text-lg ">chief Executive Officer</p>
              </div>
            </div>
          </Fade>
        </div> */}

        <div className="flex flex-col gap-y-12 lg:w-[46%] w-[90%] mx-auto pb-32">
          <Zoom>
            <p className=" mulish-font font-semibold text-2xl lg:text-[38px] lg:leading-[50px]">
              An Entrepreneurial Culture Built on Solid Partnerships
            </p>

            <p className="mulish-font leading-10 lg:text-[28px] text-lg">
              Prystine International Trading LLC is a distributor of
              fragrances and skincare products. We offer a selection of
              popular products. We specialize in luxury brands of fragrances
              and skincare and makeup essentials and gift sets items at great
              prices to wholesalers and retailers. Our products are genuine
              and original, and we are trusted by wholesalers all over the
              world.
            </p>
          </Zoom>
        </div>
        <div>
          <img
            src={aboutusBgWrapper}
            alt="wrapper"
            className="h-[500px] object-cover"
          />
        </div>
      </div>

      <div className="bg-[#E0EBFF]">
        <div className="lg:w-[50%] w-[90%] mx-auto pt-24 lg:pt-32">
          <Zoom>
            <p className=" underline mulish-font text-[30px] lg:text-[40px] mb-8">
              Partnership & Proximity
            </p>
            <p className=" mulish-font text-[20px] lg:text-[26px] mb-8">
              We Are Your Indispensable Fragrance Partner, if Not yet, Soon to
              Be
            </p>
            <p className="mulish-font text-[19px] lg:text-[20px] leading-9">
              With over 20 years of experience in distribution and
              supply chain management, we can ship anywhere in the world. We
              have branch offices in the United States and United Kingdom, and
              we carry an inventory of best-selling fragrances and skincare
              products.
            </p>
          </Zoom>
        </div>

        <div className=" lg:w-9/12 w-[90%] mx-auto mt-32">
          <Card
            title={"Product Excellence"}
            subTitle={"Fragrance Solutions That Will Put a Smile on Your Face"}
            text={
              "Nothing will kill a great product faster than a bad fragrance.  You can’t afford to lose consumers over your brand’s fragrance.  Our expertise in fragrance creation and track record should reassure you, but don’t hesitate to put us to the test again and again.  We love challenges."
            }
            extraText={
              <>
                <p>
                  We see ourselves as solution makers, where the right
                  Eurofragance perfume in your brand will make a notable
                  difference. We appreciate that fragrance is not the only
                  benefit your product must deliver, but we are of the mind that
                  brand users have high expectations when it comes to perfume.
                </p>
                <br />
                <p>
                  Fortunately, you can count on the creativity of Eurofragance
                  Perfumers. Their talent is to create fabulous fragrances that
                  will make you dream, and for you to then share this dream with
                  your consumers. In the end, if we’ve been able to put a smile
                  on your face with our olfactive creations, it’s probably
                  because you made your consumers smile.
                </p>
              </>
            }
            image={cardImg1}
            reverse={true}
            bgColor={"bg-white"}
          />
        </div>
      </div>

      <div className="h-[100px] bg-[#E0EBFF] w-full"></div>
      <div className="h-[200px] bg-white w-full"></div>

      <div className="">
        <div className=" lg:w-9/12 w-[90%] mx-auto">
          <Card
            title={"Quality & Service"}
            subTitle={
              "Boutique Service With 30 Years of Professional Expertise"
            }
            text={
              "Having now passed our thirtieth birthday and grown to nearly 400 employees with 3,500 tons of fragrance sold annually, we feel like we are just entering our prime.  Even so, we will continue to offer our customers the bespoke service they have come to appreciate from a boutique perfume house."
            }
            image={cardImg2}
            reverse={false}
            bgColor={"bg-[#E0EBFF]"}
          />

          <div className="lg:w-[50%] mx-auto pt-24 lg:pt-32">
            <Zoom>
              <p className=" underline mulish-font text-[30px] lg:text-[40px] mb-8">
                Passion & Enthusiasm
              </p>
              <p className=" mulish-font text-[20px] lg:text-[26px] mb-8">
                We Love Perfume, Empower People and Respect our Planet
              </p>
              <p className=" mulish-font text-[17px] lg:text-[18px]">
                At Eurofragance passion drives all of us. We are passionate
                about perfume in every department, and this emotion is coupled
                with the notion of respect. Passion with respect is what we are
                all about. We care about our environment, fellow humans and
                about doing the best job possible with purpose.
              </p>
            </Zoom>
          </div>
        </div>
      </div>

      <div className="h-[200px] bg-white w-full"></div>

      <Footer />
    </div>
  );
};

export default AboutUs;
