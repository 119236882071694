import React from "react";
import HamburgerMenu from "../components/HamburgerMenu";
import aboutusBgWrapper from "../assets/aboutus-bgwrapper.jpeg";
import { Parallax } from "react-scroll-parallax";
import bannerImg1 from "../assets/contact-us-banner.webp";
import bannerImg2 from "../assets/contact-us-banner2.webp";
import bannerImg3 from "../assets/contact-us-banner3.webp";
import ContactSection from "../components/ContactSection";
import Footer from "../components/Footer";
import ContactusCard from "../components/ContactusCard";
import { Fade } from "react-reveal";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { Link } from "react-router-dom";

const ContactUs = () => {
  return (
    <div>
      <HamburgerMenu color={"text-black"} />
      <div>
        <img
          src={aboutusBgWrapper}
          alt="wrapper"
          className="h-[480px] object-cover"
        />
      </div>
      <div className=" w-[90%] md:w-9/12 mx-auto">
        <Parallax speed={18}>
          <Fade left>
            <div className=" bg-[#E0EBFF] py-16 px-10 md:w-max md:py-20 md:pl-20 md:pr-40 -mt-40 md:-mt-56 relative z-10">
              <p className=" mulish-font text-3xl mb-10">Contact Us</p>
              <p className=" hidden lg:block text-black text-[26px] md:text-[35px] 2xl:text-[50px]">
                <span className=" mulish-font italic font-extralight">
                  We're closer
                </span>
                <span className=" mulish-font ">
                  {" "}
                  Than You <br />
                  Think , so
                </span>
                <span className=" mulish-font font-bold"> Get in Touch</span>
              </p>

              <p className="block w-full lg:hidden text-black text-[30px] md:text-[35px] 2xl:text-[50px]">
                <span className=" mulish-font italic ">
                  We're closer <br />
                </span>
                <span className=" mulish-font font-medium ">
                  {" "}
                  Than You Think , so <br />
                </span>
                <span className=" mulish-font font-bold"> Get in Touch</span>
              </p>
            </div>
          </Fade>
        </Parallax>
      </div>
      <Fade right>
        <ContactSection
          bannerImg={bannerImg1}
          heading={"Contact Details for Our Office"}
        />
      </Fade>
      {/* <div className=" lg:w-9/12 mx-auto w-[90%] py-5">
        <div className=" lg:w-[54%] w-[90%] mx-auto">
          <Zoom>
            <p className=" mulish-font text-[35px] my-10">Headquaters</p>
            <p className=" w-full h-[1px] bg-black"></p>
          </Zoom>
          
          <div className="flex justify-between md:flex-row flex-col gap-y-8 gap-x-5">
            <Fade left>
              <ContactusCard
                country={"Dubai"}
                addressCountry={"dubai"}
                officeName={"EUROFRAGANCE, S.L.U."}
                type={"Offices"}
                addressLine1={"C/Camí de Can Camps, 17-19 Kibo Building "}
                addressLine2={"08174 Sant Cugat del Vallés"}
                phone={"+34 936 97 23 61"}
                email={"abcexample123@gmail.com"}
              />
            </Fade>

            <Fade right>
              <ContactusCard
                country={""}
                addressCountry={"dubai"}
                officeName={"EUROFRAGANCE, S.L.U."}
                type={"Manufacturing plant"}
                addressLine1={"C/Camí de Can Camps, 17-19 Kibo Building"}
                addressLine2={"08174 Sant Cugat del Vallés"}
                phone={"+34 936 97 23 61"}
                email={"abcexample123@gmail.com"}
              />
            </Fade>
          </div>
        </div>
      </div> */}
      {/* <div className=" lg:w-9/12 mx-auto w-[90%] py-5">
        <div className=" lg:w-[54%] w-[90%] mx-auto">
          <Zoom>
            <p className=" mulish-font text-[35px] my-10">
              Affiliates and Manufacturing plants
            </p>
            <p className=" w-full h-[1px] bg-black"></p>
          </Zoom>
          
          <div className="grid justify-between grid-cols-1 md:grid-cols-2 gap-y-8 gap-x-5">
            <Fade left>
              <ContactusCard
                country={"Spain"}
                addressCountry={"spain"}
                officeName={"EUROFRAGANCE, S.L.U."}
                type={"Offices"}
                addressLine1={"C/Camí de Can Camps, 17-19 Kibo Building "}
                addressLine2={"08174 Sant Cugat del Vallés"}
                phone={"+34 936 97 23 61"}
                email={"abcexample123@gmail.com"}
              />
            </Fade>

            <Fade right>
              <ContactusCard
                country={"U.A.E"}
                addressCountry={"UAE"}
                officeName={"EUROFRAGANCE, S.L.U."}
                type={"Manufacturing plant"}
                addressLine1={"C/Camí de Can Camps, 17-19 Kibo Building"}
                addressLine2={"08174 Sant Cugat del Vallés"}
                phone={"+34 936 97 23 61"}
                email={"abcexample123@gmail.com"}
              />
            </Fade>
            <Fade left>
              <ContactusCard
                country={"Singapore"}
                addressCountry={"Singapore"}
                officeName={"EUROFRAGANCE, S.L.U."}
                type={"Offices"}
                addressLine1={"C/Camí de Can Camps, 17-19 Kibo Building "}
                addressLine2={"08174 Sant Cugat del Vallés"}
                phone={"+34 936 97 23 61"}
                email={"abcexample123@gmail.com"}
              />
            </Fade>

            <Fade right>
              <ContactusCard
                country={"India"}
                addressCountry={"India"}
                officeName={"EUROFRAGANCE, S.L.U."}
                type={"Manufacturing plant"}
                addressLine1={"C/Camí de Can Camps, 17-19 Kibo Building"}
                addressLine2={"08174 Sant Cugat del Vallés"}
                phone={"+34 936 97 23 61"}
                email={"abcexample123@gmail.com"}
              />
            </Fade>
            <Fade left>
              <ContactusCard
                country={"china"}
                addressCountry={"china"}
                officeName={"EUROFRAGANCE, S.L.U."}
                type={"Offices"}
                addressLine1={"C/Camí de Can Camps, 17-19 Kibo Building "}
                addressLine2={"08174 Sant Cugat del Vallés"}
                phone={"+34 936 97 23 61"}
                email={"abcexample123@gmail.com"}
              />
            </Fade>
          </div>
        </div>
      </div> */}
      {/* <Fade right>
        <ContactSection
          bannerImg={bannerImg2}
          heading={"We Are Much Closer Than You Think, Check Us Out"}
          text={
            "We have a close knit network of international strategic partners."
          }
        />
      </Fade> */}
      <div className=" lg:w-9/12 mx-auto w-[90%]">
        <div className=" lg:w-[54%] w-[90%] mx-auto">
          <div className="flex justify-between md:flex-row flex-col gap-y-8 gap-x-5 -mt-20">
            <Fade left>
              <div>
                <ContactusCard
                  country={"United Arab Emirates"}
                  officeName={"Prystine International Trading LLC."}
                  type={"Office"}
                  addressLine1={
                    "Openhub Business Center, Al Nisf Building - 3-01"
                  }
                  addressLine2={
                    "Airport Rd - Garhoud - Dubai - United Arab Emirates"
                  }
                  phone={"+971 42894853"}
                />
                <Link className="flex items-center underline gap-2" target='blank' to={"https://wa.me/971528760867"}>
                  <AiOutlineWhatsApp className=' cursor-pointer' />
                  WhatsApp Us
                </Link>
              </div>
            </Fade>
          </div>
        </div>
      </div>
      <div className=" w-full h-[100px]"></div>
      {/* <Fade left>
        <ContactSection
          bannerImg={bannerImg3}
          heading={
            "We Are Represented by Some Fabulous Agents in Select Countries"
          }
          text={
            "In over a dozen countries, we work with partners who share our passion for perfume and the same work ethic. Feel free to contact our headquarters to get the name and address of the Eurofragance representative nearest you."
          }
        />
      </Fade> */}
      <div className=" w-full h-[100px]"></div>
      <Footer />
    </div>
  );
};

export default ContactUs;
