import React, { useState } from 'react'
import { CiMenuBurger } from "react-icons/ci";
import { RxCross1 } from "react-icons/rx";
import { Link } from "react-router-dom";

const HamburgerMenu = ({color}) => {
    const [showMenu, setShowMenu] = useState(false);
  return (
    <>
        <div className={` bg-[#E0EBFF] z-20 px-10 py-10 ${showMenu ? "side-active-nav":"side-nav"}`}>
          <div className="flex flex-col">
            <RxCross1 className={` text-4xl cursor-pointer ${!showMenu && "button-fade-out"}`} onClick={() => setShowMenu(false)} />
            <div className="flex flex-col gap-y-16 mulish-font text-2xl text-black px-10 py-20">
              <Link to={"/"}>Home</Link>
              <Link to={"/about-us"}>About Us</Link>
              <Link to={"/contact-us"}>Contact Us</Link>
            </div>
          </div>
        </div>
      <div className=" absolute text-white right-8 top-8 md:right-16 lg:right-32 md:top-10">
          <CiMenuBurger 
            className={` text-4xl cursor-pointer ${color ? color : "text-white"}`}
            onClick={()=>setShowMenu(true)}
          />
        </div>
    </>
  )
}

export default HamburgerMenu