import React from 'react'

const ContactSection = ({bannerImg , heading , text}) => {
  return (
    <div className="h-[80vh] w-[90%] lg:w-9/12 mx-auto flex flex-col justify-center items-center ">
      <img src={bannerImg} alt="contact-us-banner" className='lg:w-[54%] w-[90%]'/>
      <div className="lg:w-[54%] w-[90%] mt-16">
        <p className=" mulish-font text-[30px] lg:text-[40px]">
          {heading}
        </p>
        <p className=" mulish-font text-[17px] lg:text-[24px]">
          {text}
        </p>
      </div>
    </div>
  )
}

export default ContactSection