import React from 'react'
import {AiOutlineInstagram , AiOutlineFacebook , AiFillTwitterSquare, AiOutlineWhatsApp} from "react-icons/ai";
import { Link } from 'react-router-dom';
const Footer = () => {
  return (
    <div className=' bg-[#E0EBFF] text-black '>
        <div className='w-[85%] xl:w-9/12 mx-auto pt-12'>
            <div className='flex justify-between lg:flex-row flex-col gap-y-20'>
                <div>
                    <p className=' yeseva-font tracking-wider text-[30px] lg:text-[50px]'>Prystine</p>
                    <div className='flex gap-x-8 items-center text-4xl mt-10'>
                        <AiOutlineInstagram className=' cursor-pointer'/>
                        <AiOutlineFacebook className=' cursor-pointer'/>
                        <AiFillTwitterSquare className=' cursor-pointer'/>
                        <Link target='blank' to={"https://wa.me/971528760867"}>
                        <AiOutlineWhatsApp className=' cursor-pointer'/>
                        </Link>
                    </div>
                </div>
                <div className='lg:w-[30%] flex justify-between'>
                    <div>
                        <p className=' mulish-font font-bold tracking-wider text-[20px] lg:text-[35px] mb-10'>Links</p>
                        <p className=' mulish-font lg:text-xl mb-5 cursor-pointer'>About Us</p>
                        <p className=' mulish-font lg:text-xl mb-5 cursor-pointer'>Contact Us</p>
                        <Link to={"/"} className=' mulish-font lg:text-xl mb-5 cursor-pointer'>Home</Link>
                    </div>
                    <div>
                    <p className=' mulish-font font-bold tracking-wider text-[20px] lg:text-[35px] mb-10'>HQ</p>
                        <p className=' mulish-font lg:text-xl'>Openhub Business Center,</p>
                        <p className=' mulish-font lg:text-xl'>Al Nisf Building 3-01,</p>
                        <p className=' mulish-font lg:text-xl '>Airport Rd, Garhoud,</p>
                        <p className=' mulish-font lg:text-xl '>Dubai - United Arab Emirates</p>
                        <p className=' mulish-font lg:text-xl '>+971 42894853</p>
                    </div>
                </div>
            </div>

            <div className=' w-full h-[1px] bg-[#2525251c] mt-32'></div>

            <div className='flex'>
                <p className='my-8'>
                © 2023 Prystine. All rights reserved
                </p>
                <p>

                </p>
            </div>
        </div>
    </div>
  )
}

export default Footer