import React from 'react';

const ContactusCard = ({country , officeName , type , addressLine1 , addressLine2 , addressCountry , phone , email}) => {
  return (
    <div className=' mulish-font text-lg'>
        <div className={` font-semibold text-2xl my-8 ${country ? "":"h-[36px]"}`}>
            {country}
        </div>
        <p className=' font-semibold text-xl my-4'>
            {type}
        </p>
        <p >
            {officeName}
        </p>
        <p>
            {addressLine1}
        </p>
        <p>
            {addressLine2}
        </p>
        <p>
            {addressCountry}
        </p>
        <p className=' mt-1'>
            Phone {phone}
        </p>
        <p className=' underline text-[19px] mt-1 cursor-pointer'>
            {email}
        </p>
    </div>
  );
};

export default ContactusCard;