import React from "react";
import { useState } from "react";
import { Fade, Slide } from "react-reveal";

const Card = ({
  reverse,
  image,
  title,
  subTitle,
  text,
  bgColor,
  extraText,
}) => {
  const [showText, setShowText] = useState(false);

  return (
    <>
        {
        reverse ? (
            <Fade right>
      <div
        className={`flex flex-col gap-y-10 ${
          reverse === true ? "lg:flex-row-reverse" : "lg:flex-row"
        }`}
      >
        <div>
          <img
            src={image}
            alt="card-image"
            className={` ${
              reverse === true ? "lg:-ml-12 lg:mt-16" : "lg:ml-12 lg:mt-24 "
            } md:w-[60%] lg:w-full relative z-10 ${
              reverse === true ? "lg:w-[90%]" : "lg:w-[80%]"
            }`}
          />
        </div>

        <div
          className={`${bgColor} py-16 px-8 sm:p-20 lg:p-28 lg:w-[82%] h-max`}
        >
          <p className=" underline mulish-font text-[30px] lg:text-[40px] mb-8">
            {title}
          </p>
          <p className=" mulish-font text-[20px] lg:text-[26px] mb-8">
            {subTitle}
          </p>
          <p className=" mulish-font text-[17px] lg:text-[18px]">{text}</p>
          <p
            className={` mulish-font text-[17px] lg:text-[18px] fade-in-text ${
              showText ? "py-8" : "py-4"
            }`}
          >
            {showText && extraText}
          </p>
          {/* <div>
            <p
              className=" cursor-pointer mulish-font text-[17px] lg:text-[18px]"
              onClick={() => setShowText(!showText)}
            >
              {showText ? "Read Less" : "Keep Reading"}
            </p>
          </div> */}
        </div>
      </div>
    </Fade>
        ) : (
            <Fade left>
      <div
        className={`flex flex-col gap-y-10 ${
          reverse === true ? "lg:flex-row-reverse" : "lg:flex-row"
        }`}
      >
        <div>
          <img
            src={image}
            alt="card-image"
            className={` ${
              reverse === true ? "lg:-ml-12 lg:mt-16" : "lg:ml-12 lg:mt-24 "
            } md:w-[60%] lg:w-full relative z-10 ${
              reverse === true ? "lg:w-[90%]" : "lg:w-[80%]"
            }`}
          />
        </div>

        <div
          className={`${bgColor} py-16 px-8 sm:p-20 lg:p-28 lg:w-[82%] h-max`}
        >
          <p className=" underline mulish-font text-[30px] lg:text-[40px] mb-8">
            {title}
          </p>
          <p className=" mulish-font text-[20px] lg:text-[26px] mb-8">
            {subTitle}
          </p>
          <p className=" mulish-font text-[17px] lg:text-[18px]">{text}</p>
          <p
            className={` mulish-font text-[17px] lg:text-[18px] fade-in-text ${
              showText ? "py-8" : "py-4"
            }`}
          >
            {showText && extraText}
          </p>
          {/* <div>
            <p
              className=" cursor-pointer mulish-font text-[17px] lg:text-[18px]"
              onClick={() => setShowText(!showText)}
            >
              {showText ? "Read Less" : "Keep Reading"}
            </p>
          </div> */}
        </div>
      </div>
    </Fade>
        )
    }
    </>
  );
};

export default Card;
